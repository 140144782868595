.dashicons-incorp:before {
    content: url('../../../assets/icons/incorp.svg');
    width: 20px;
    height:20px;
}

.wp-block-iootus-block-add-ons-text-image-content {
	background-color: unset;
	margin: unset !important;
	padding: unset;
	max-width: unset;
	color: unset;
}

h2.text-primary {
    color: #BB2121;
    font-family: "Roboto Bold", Roboto Reguler;
    font-size: 2.1rem;
    font-weight: 700;
}
